.sb-document-upload {
  margin-top: 15px;
}

.sb-document-upload
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.sb-document-upload
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
  max-width: 370px;
  min-height: 145px;
  height: auto;
  border: 1px dashed var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding: 20px;
}

.sb-document-upload
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: var(--light-gray);
}

.sb-identify-fileupload
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.sb-identify-fileupload
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  background-color: transparent;
}

.sb-document-upload .sb-fileUpload-icon svg {
  font-size: 15px;
  color: var(--black);
  display: block;
}

.hover-buttons {
  position: relative;
  opacity: 0.7;
}

.hover-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 5;
}

.hover-icon .anticon-eye {
  margin-right: 12px;
  font-size: 25px;
}

.hover-icon .anticon-delete {
  font-size: 25px;
}

.sb-isc-info {
  color: var(--black-80, rgba(26, 32, 44, 0.8));
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sb-isc-info p {
  line-height: 25px;
}

.sb-isc-info ul {
  list-style: circle;
  line-height: 25px;
}

.show-img {
  border: 1px solid #d9d9d9;
  margin-left: 10px;
}

.overlay {
  position: absolute;
  top: 46px;
  left: 2px;
  width: 99%;
  height: 80%;
  background-color: #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 12px;
}

.eye-icon {
  font-size: 2rem;
  color: white;
}

.image-container:hover .overlay {
  opacity: 1;
}

.kl-isc-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #1a202c66;
}

.ant-select-selection-search-input {
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(26, 32, 44, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  height: 40px;
  padding: var(--16, 16px) var(--20, 20px);
  width: 100%;
}

.pdf-icon {
  font-size: 65px;
  color: var(--kilde-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.view-modal .ant-modal-content {
  padding: 40px;
  border-radius: 16px;
}

.view-modal .ant-modal-header {
  margin-bottom: 16px;
}
.view-modal .ant-modal-close {
  top: 42px;
  right: 38px;
  color: #1a202c;
}
.view-modal .ant-modal-close-icon {
  font-size: 18px;
}
