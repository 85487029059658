.kl-ty-maindiv {
    margin: 60px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1a202ca1;
}

 @media (max-width:576px){
    .kl-ty-maindiv{
 margin: 20px;
    }
   
 }