.kl-button-icon {
  border-radius: 12px;
  border-color: var(--black-10, rgba(26, 32, 44, 0.1)) !important;
  height: 40px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.kl-button-icon .ant-btn-icon {
  position: absolute;
  left: 120px;
  font-size: 24px;
  transform: translateY(-50%);
  top: 50%;
}

.kl-button-icon .ant-btn-icon svg:hover {
  color: black;
}
