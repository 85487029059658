.sb-select.ant-select {
  width: 100%;
}

.sb-select-error.ant-select {
  width: 100%;
}

.sb-select.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid var(--dark-blue-10, rgba(26, 32, 44, 0.1));
  border-radius: var(--12);
  height: 40px;
  width: 100%;
  align-items: center;
  text-align: start;
  padding: var(--4, 4px) var(--12, 12px);
}

.sb-select-error.ant-select-show-search.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #e74c3c;
  border-radius: var(--12);
  height: 40px;
  width: 100%;
  align-items: center;
  text-align: start;
  padding: var(--4, 4px) var(--12, 12px);
}

.sb-select-error .ant-select-selection-placeholder {
  color: var(--dark-blue-20);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sb-select .ant-select-selection-placeholder {
  color: var(--dark-blue-20);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.ant-select-show-search.sb-select.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 100%;
}

.ant-select-selector {
  background: var(--white) !important;
}

.ant-select-multiple {
  font-size: 12px;
}
