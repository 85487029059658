.dashboard-page-div {
  padding: 56px 48px 56px;
}

.dashboard-earned-div {
  border-radius: 16px;
  padding: 24px !important;
  background: var(--kilde-blue);
}

.dashboard-white-14-text {
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  line-height: 20px;
}

.dashboard-white-32-text {
  font-size: 32px;
  font-weight: 600;
  color: var(--white);
  line-height: 36px;
}

.dashboard-head-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.dashboard-currency-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: var(--black);
  border-color: transparent !important;
}

.dashboard-currency-btn {
  border-radius: 8px;
  border: 1px solid #1a202c1a;
  background: #f8f7f2;
}
.dashboard-currency-active-btn {
  border-radius: 8px;
  border: transparent;
  color: #22b5e9 !important;
}
.currency-btn-div {
  display: flex;
  gap: 8px;
  align-items: center;
}

.dashboard-spinner span {
  font-size: 80px !important;
}

.dashboard-go-back-btn {
  display: flex;
  padding: var(--8, 8px) var(--16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  border-radius: var(--12, 12px);
  border: 1px solid var(--Primary-Blue, #22b5e9);
  background: var(--white-100, #fff);
  position: fixed;
  left: 20px;
  bottom: 20px;
  height: 34px;
  width: fit-content;
}

.dashboard-go-back-btn :hover {
  background: var(--white-100, #fff);
}

.dashboard-go-back-btn span {
  color: var(--Primary-Blue, #22b5e9);
  text-align: center;
  font-weight: 400;
  line-height: 18px;
}

.upgrade-kilde-modal .ant-modal-content {
  border-radius: 16px;
  background: var(--white);
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.upgrade-kilde-modal .ant-modal-body {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.upgrade-kilde-modal .ant-modal-body > div {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.upgrade-kilde-img {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 16px 16px 0px 0px;
}

.ant-modal-subDiv {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.upgrated-modal-close-btn {
  padding: 0;
  border: none;
  background: transparent;
  height: 0;
  position: absolute;
  top: 13px;
  right: 11px;
}

@media (max-width: 576px) {
  .dashboard-page-div {
    padding: 48px 20px;
  }
}
