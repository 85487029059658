.sb-login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login-form-btn-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.sb-login-stepcount p {
  color: var(--kilde-blue);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.sb-forgot-new-here {
  margin-top: 25px;
  text-align: center;
}
.singpass-datepicker {
  padding: 8px 11px 8px;
  border-radius: 12px;
}
.sb-forgot-new-here a {
  color: var(--kilde-blue);
  font-size: 14px;
  font-weight: 500;
  line-height: 13.5px;
  margin-left: 10px;
}
.sb-login-actions .sb-button-default {
  width: 250px;
}

.sb-pdf-modal .ant-modal-close {
  top: 8px !important;
}

.sb-pdf-modal .ant-modal-close-icon {
  font-size: 20px;
}

.sb-login-actions .ant-divider-plain {
  margin: 0px 0px 10px 0px;
}
.test-google-css iframe {
  margin: 0 !important;
  width: 100% !important;
}
.referral-error {
  border: 1px solid red;
  border-radius: 12px;
}
.or-line {
  text-align: center;
  font-size: 19px;
  margin: 0;
  font-weight: 700;
}
.register-btn-div {
  padding: 4px 0px 10px 0px;
}
.test-google-css > div {
  width: 100%;
}

.btn-singpass {
  padding: 0px 15px 80px 15px;
  width: 100%;
  border-radius: 12px;
  border-color: var(--black-10, rgba(26, 32, 44, 0.1)) !important;
}
.singpass-text {
  font-size: 14px;
  color: #1a202c;
}

.btn-singpass .ant-btn-loading-icon {
  position: absolute;
  left: 106px;
  top: 32px;
}

.ant-form-item .ant-form-item-explain-error {
  color: #e74c3c !important;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  text-align: start;
}

@media (max-width: 1200px) {
  .sb-login-container {
    padding: 20px 44px 66px;
    width: 40vw;
  }
}
@media (max-width: 1024px) {
  .sb-login-container {
    padding: 20px 40px 66px 40px;
    margin: 60px auto;
    width: 45vw;
  }
}
@media (max-width: 992px) {
  .sb-login-container {
    width: 75%;
    padding: 20px 60px 66px 60px;
  }
}
@media (max-width: 575px) {
  .sb-login-container {
    width: 100%;
    border: none;
    margin: 0;
    padding: 20px 20px 66px 20px;
  }

  .register-redirect-link {
    display: grid;
    gap: 3px;
  }
}
@media (max-width: 320px) {
  .sb-login-container {
    width: 100%;
    border: none;
    margin: 0;
    padding: 5px;
  }

  .register-redirect-link {
    display: grid;
    gap: 3px;
  }
}
