.input-label {
  color: #1a202c66;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.input-span-below {
  color: #1a202c33;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.page-title {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Inter Tight";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.stratergy-actione-button-div {
  display: none;
}

.strategy-action-main-div {
  display: flex;
  justify-content: space-between;
}

.stratergy-action-delete-btn {
  border-radius: var(--12, 12px);
  border: 1px solid #ff4747;
  padding: 8px 16px;
  font-size: 14px;
  color: var(--Secondary-Red, #ff4747);
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.stratergy-action-delete-btn:hover {
  color: var(--Secondary-Red, #ff4747) !important;
  border: 1px solid #ff4747 !important;
}

.delete-stratergy-icon-btn {
  color: #ff4747;
}

@media (max-width: 576px) {
  .remain-loan-stratergy {
    margin-top: 8px;
  }
  .stratergy-action-icon-div {
    display: none;
  }
  .stratergy-actione-button-div {
    display: flex;
    width: 100%;
    gap: 4px;
    margin: 0 0 24px 0px;
  }

  .strategy-action-main-div {
    flex-direction: column;
  }
}
