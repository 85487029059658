.kl-checkcircle {
  width: 60px;
  height: 60px;
}

.resend-text {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 8px;
}
