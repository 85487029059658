.trance-listing-main-div {
  padding: 56px 48px;
  position: relative;
}

@media (max-width: 576px) {
  .trance-listing-main-div {
    padding: 48px 20px;
  }
}
