.rejected-user-div li{
    list-style-type: circle;
}

.color-light-black{
   color:#1A202CCC;
  }

  .rejected-user-head{
    color: var(--kilde-blue);
    font-weight: 600;
  }

  .kl-link a{
    color: var(--kilde-blue);
    text-decoration: underline !important;
  }

  .kl-link a:hover{
    color: var(--kilde-blue);
  }

  .sb-rejected-form-container{
    width: 55%;
    margin: auto;
    padding: 40px;
    border-radius: var(--24, 24px);
    background: var(--white-100, #fff);
  }

  @media (max-width: 1300px) {
    .sb-rejected-form-container {
      width: 55%;
      padding: 20px;
    }
  }
  
  @media (max-width: 1024px) {
    .sb-rejected-form-container {
      width: 75%;
    }
  }
  
  @media (max-width: 992px) {
    .sb-rejected-form-container {
      width: 85%;
      padding: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .sb-rejected-form-container {
      width: 100%;
      padding: 0;
    }
  }
  
  @media (max-width: 575px) {
    .sb-rejected-form-container {
      width: 100%;
      padding: 0;
    }
  
    .fp-head {
      font-size: 20px;
    }
  }