.radio-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid;
  width: 100%;
  height: 52px;
  cursor: pointer;
}

.radio-container .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #22b5e9;
  background-color: #22b5e9;
}

.radio-container p {
  font-size: 14px;
}

.radio-container p.checked {
  color: #22b5e9;
}

.radio-container p.unchecked {
  color: #000;
}
