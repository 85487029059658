.auth-drawer-menu-list .ant-menu-item-only-child {
  margin-left: 0px;
}

.ant-menu-submenu-title {
  margin-top: 15px !important;
}

.ant-menu-item {
  margin-top: 15px !important;
}
