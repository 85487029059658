.sb-onboarding-form-container {
  width: 680px;
  max-width: 680px;
  margin: auto;
  text-align: center;
  padding: 50px;
  border-radius: var(--24, 24px);
  background: var(--white-100, #fff);
}

.sb-onboarding-register-form-container {
  width: 680px;
  max-width: 680px;
  margin: auto;
  text-align: center;
  border-radius: var(--24, 24px);
  background: var(--white-100, #fff);
}

.singpass-sub-text {
  font-size: 12px;
  line-height: 18px;
  color: #1a202c;
  padding: 0px 50px;
}

.register-padd-container {
  padding: 0px 100px 0 100px;
}

.forgot-pass-subdiv {
  margin-top: 20px;
  text-align: left;
}

.fp-link {
  color: var(--kilde-blue);
  text-decoration: none;
  font-size: 14px;
}

@media (max-width: 1300px) {
  .sb-onboarding-form-container {
    width: 680px;
    max-width: 680px;
    padding: 80px;
  }

  .sb-onboarding-register-form-container {
    width: 680px;
    max-width: 680px;
    padding: 30px;
  }

  .register-padd-container {
    padding: 0px 75px 0 75px;
  }
  .singpass-sub-text {
    padding: 0px 7px;
  }
}

@media (max-width: 1024px) {
  .sb-onboarding-form-container {
    width: 680px;
    max-width: 680px;
  }
  .sb-onboarding-register-form-container {
    width: 680px;
    max-width: 680px;
  }
  .register-padd-container {
    padding: 0px 75px 0 75px;
  }
}

@media (max-width: 992px) {
  .sb-onboarding-form-container {
    width: 630px;
    max-width: 630px;
  }
  .sb-onboarding-register-form-container {
    width: 630px;
    max-width: 630px;
  }
}

@media (max-width: 768px) {
  .sb-onboarding-form-container {
    width: 100%;
  }
  .sb-onboarding-register-form-container {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .sb-onboarding-form-container {
    width: 100%;
    padding: 48px 28px !important;
  }

  .sb-onboarding-register-form-container {
    width: 100%;
    padding: 15px;
  }

  .register-padd-container {
    padding: 0px 15px 0 15px;
  }

  .fp-head {
    font-size: 20px;
  }
}
